<template>
  <div class="content">
    <img class="signsuccess" src="@/static/signin/title.png" alt="">
    <div class="signinfo">
      <p class="signinfo_title">《{{ signInfo.activity_name }}》</p>
      <div class="signinfo_time">
        <div class="left_box"><img src="@/static/signin/time.png" alt=""><span class="left_text">时间</span></div>
        <div class="right_text">{{ signInfo.start_time }}</div>
      </div>
      <div class="signinfo_local">
        <div class="left_box"><img src="@/static/signin/local.png" alt=""><span class="left_text">地点</span></div>
        <div class="right_text">{{ signInfo.activity_space }}</div>
      </div>
      <div class="signinfo_teacher">
        <div class="left_box"><img src="@/static/signin/teacher.png" alt=""><span class="left_text">讲师</span></div>
        <div class="right_text">{{ signInfo.speaker_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { signinsuccessApi } from '@/api/signin'
export default {
  data() {
    return {
      signInfo: {},
      act_id: 0
    }
  },
  created() {
    this.act_id = this.$route.query.actId
    this.getData()
  },
  methods: {
    getData() {
      const params = {
        act_id: this.act_id
      }
      signinsuccessApi(params).then(res => {
        if (res.errNo === 0) {
          this.signInfo = res.result
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
    background: #f5f5f5;
    width: 100%;
    height: 100%;
}
.signsuccess {
    width: 100%;
}
.signinfo {
    border-radius: 10px;
    background: #fff;
    width: 90%;
    margin: 20px auto;
    padding: 0 20px;
    .signinfo_title {
        font-size: 36px;
        color: #111111;
        font-weight: bold;
        padding: 40px 0;
    }
    .signinfo_time,.signinfo_local,.signinfo_teacher {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
        border-top: 1px solid #F0F0F0;
        .left_box {
            position: relative;
            width: 220px;
            img {
                width: 50px;
                height: 50px;
                position: absolute;
                left: 0;
                top: -6px;
            }
            span {
                padding-left: 60px;
            }
        }
    }
}
.left_text {
    font-size: 28px;
    color: #222222;
}
.right_text {
    font-size: 26px;
    color: #999999;
}
</style>
